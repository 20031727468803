<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/players/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">

                <template #field-valueType>
                    <sc-form-field :form="form" field="valueType" v-if="form.fields.datatype !== 'BOOLEAN'" name="Value type*">

                        <nr-select :form="form" field="valueType"
                                   :options="{
                                    resource: `${resource.api}/value-types`,
                                    empty: 'select',
                                    targets: 'none'
                                   }">
                        </nr-select>

                    </sc-form-field>
                    <div v-else></div>
                </template>

                <template #field-predefinedValues>
                    <sc-form-field :form="form" field="predefinedValues" v-if="form.fields.valueType === 'PICKLIST'" name="Predefined Values">
                        <div style="display: flex;">
                            <input :type="form.fields.datatype === 'INTEGER' ? 'number' : 'text'"
                                   v-model="picklistValue" style="margin-right: 1em;flex:1;" id="picklistInput"/>
                            <sc-button style="flex:1;" @click="updatePicklist">Add Value</sc-button>
                        </div>

                        <div class="values" v-if="Array.isArray(form.fields.predefinedValues)">
                            <template v-for="(v,i) in form.fields.predefinedValues">
                                <div class="value" v-if="v.value" @click="form.fields.predefinedValues.splice(i,1)">
                                    {{ v.value }}
                                    <div class="remove">
                                        <awesome-icon icon="times"/>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </sc-form-field>
                    <div v-else></div>
                </template>

            </sc-form>
        </div>
    </div>
</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
      types: {
        data: [],
        value: []
      },
      picklistValue: undefined
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        name: {
          name: 'Name*'
        },
        datatype: {
          name: 'Data type*',
          component: 'nr-select',
          options: {
            resource: `${this.resource.api}/data-types`,
            empty: 'select',
            targets: 'none'
          }
        },
        valueType: {
        },
        predefinedValues: {
          default: []
        }
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/players/${this.resource.route}`)
      }
    })
  },

  methods: {
    submit() {
      if (this.form.fields.valueType === 'PICKLIST' && this.form.fields.predefinedValues.length === 0) {
        this.form.errors.record({"predefinedValues": ["The predefinedValues field is required."]});
        return;
      }

      this.form.post();
    },
    updatePicklist(e) {
      this.form.errors.clear();
      if (!Array.isArray(this.form.fields.predefinedValues)) {
        this.form.fields.predefinedValues = [];
      }
      if (this.picklistValue) {
        this.form.fields.predefinedValues.push({value: this.picklistValue});
        this.picklistValue = undefined;
        document.getElementById('picklistInput').focus()
      } else {
        this.form.errors.record({"predefinedValues": ["The predefinedValues can't be blank."]})
      }
    }
  }
}
</script>

<style scoped lang="scss">

.values {
    margin: .5em 0;
    display: flex;
    flex-flow: wrap;
    max-width: 100%;

    .value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5em .75em;
        margin: .5em;
        cursor: pointer;
        border-radius: .25em;
        border: 1px solid #e2e2e2;
        transition: all $easing $transition-duration;

        &:first-child {
            margin-left: 0;
        }

        .remove {
            opacity: .5;
            margin-left: .5em;
            display: flex;
            align-items: center;
            transition: opacity $easing $transition-duration;
        }

        &:hover {
            border-color: $color-primary;

            .remove {
                opacity: 1;
            }
        }
    }
}

</style>